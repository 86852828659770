export default class State {
  constructor() {
    this.sessionId = "";
    this.isCommunityLoggedIn = false;
    this.contextArr = {};
    this.userInputArr = [];
    this.conversationArr = [];
    this.detectedIntents = [];
    this.branchIndex = 0;
    this.successBranch = 0;
    this.useContext = false;
    this.isAudioEnabled = false;
    this.convJson = {};
    this.endOfConv = "";
    this.isIA=false;
    this.noResult = false;
    this.prevIntent = "";
    this.firstOccurance = false;
    this.isEmail = false;
    this.distanceMap = new Map();
    this.zipCodeData = {};
    this.stateJson = {
      selected_resource:"",
      user_name: "",
      my_dob: "",
      spouse_dob: "",
      medicare_type: "",
      isUs_territories: "",
      state: "",
      dependants: "",
      beneficiaryType: "",
      is_spouse_dob: false,
      lat:"",
      lng:"",
      distance: "",
      zipcode: "",
      zipcodeList: [],
      care_rating: "",
      survey_rating: "",
      quality_rating: "",
      staffing_rating: "",
      certification: "",
      inhospital: "",
      ccrc_facility: "",
      council_type: "",
      ec_city: "",
      ec_state: "",
      ec_services: [],
      ec_facility: "",
      isFav: false,
      miles:"",
      raSelection:{},
      preferred_lang:"",
      nursing_care: ["Yes", "No"],
      physical_therapy: ["Yes", "No"],
      occupational_therapy: ["Yes", "No"],
      speech_pathology: ["Yes", "No"],
      medical_social_services: ["Yes", "No"],
      home_health_aide_services: ["Yes", "No"],
    };

    this.resetIntentName = "Start again";
    this.dependantsIntent = "Medicaid - get dependents";
    this.welcomeIntent = "Welcome";
    this.service = sessionStorage.getItem("service")
      ? sessionStorage.getItem("service")
      : "medicare";
    this.audioIntent = "";
    this.successIdentifier = ["#SUCCESS#"];
    this.failureIdentifier = ["<FAILURE>", "#ERROR#", "#Error#"];
    this.suggestionsToDisable = ["Assisted Living", "Long-Term Care Hospital"];
    this.keiko_avatar_parent_class = "keiko-avatar";
    this.keiko_icon_class = "Keiko-icon";
    this.keiko_message_class = "keiko-message";
    this.privateInsurance = "";
  }

  static getInstance() {
    if (this.state) {
      return this.state;
    } else {
      this.state = new State();
      return this.state;
    }
  }

  resetVariable() {
    this.sessionId = "";
    this.contextArr = {};
    this.userInputArr = [];
    this.conversationArr = [];
    this.detectedIntents = [];
    this.branchIndex = 0;
    this.successBranch = 0;
    this.useContext = false;
    this.isAudioEnabled = false;
    this.convJson = {};
    this.endOfConv = "";
    this.noResult = false;
    this.prevIntent = "";
    this.firstOccurance = false;
    this.isEmail = false;
    this.stateJson = {
      selected_resource:"",
      user_name: "",
      my_dob: "",
      spouse_dob: "",
      medicare_type: "",
      isUs_territories: "",
      state: "",
      dependants: "",
      beneficiaryType: "",
      is_spouse_dob: false,
      userSelection:"",
      distance: "",
      zipcode: "",
      zipcodeList: [],
      miles:"",
      raSelection:{},
      care_rating: "",
      survey_rating: "",
      quality_rating: "",
      staffing_rating: "",
      certification: "",
      inhospital: "",
      ccrc_facility: "",
      council_type: "",
      ec_city: "",
      ec_state: "",
      ec_services: [],
      ec_facility: "",
      nursing_care: ["Yes", "No"],
      physical_therapy: ["Yes", "No"],
      occupational_therapy: ["Yes", "No"],
      speech_pathology: ["Yes", "No"],
      medical_social_services: ["Yes", "No"],
      home_health_aide_services: ["Yes", "No"],
    };

    this.resetIntentName = "Start again";
    this.dependantsIntent = "Medicaid - get dependents";
    this.welcomeIntent = "Welcome";
    this.service = sessionStorage.getItem("service")
      ? sessionStorage.getItem("service")
      : "medicare";
    this.audioIntent = "";
    this.successIdentifier = ["#SUCCESS#"];
    this.failureIdentifier = ["<FAILURE>", "#ERROR#", "#Error#"];
    this.suggestionsToDisable = ["Assisted Living", "Long-Term Care Hospital"];
    this.keiko_avatar_parent_class = "keiko-avatar";
    this.keiko_icon_class = "Keiko-icon";
    this.keiko_message_class = "keiko-message";
    this.privateInsurance = "";
  }
}
