import Urls from "../contants/url";
import State from "../state/state";
import axios from "axios";

const hark = require("hark");
const linkSuggestionMap = require("../config/json/link-mapping.json");

const TIME_TO_WAIT_FOR_AUDIO = 8000;
let slowLoad; // = window.setTimeout( () => {}, TIME_TO_WAIT_FOR_AUDIO);

let recognition;

const textOnlyTagName = "text";
const linkTag = "a";

const backIntentName = "Back one step";
const resetIntentName = "Start again";
const dependantsIntent = "Medicaid - get dependents";
const welcomeIntent = "Welcome";
const dobIntentName = "Beneficiary type - self";
const spouseDOBContent = "spouse’s date of birth";
const emailContent = "email address";

const moreId = "Private_Insurance";
const keiko_avatar_parent_class = "keiko-avatar";
const keiko_animation_class = "keiko-animate";

/* DatePicker Code */
export const dp_month_array = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

/* DatePicker Code - for audio*/
export const dp_month_array_full = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

let currentIntentName = "";
let currentBotMessage = "";

let resultReceived = false;

const rhymingNumbers = [
  ["one", "on"],
  ["two", "tu", "to"],
  ["three", "there", "tree"],
  ["four", "for"],
  ["five", "fiv"],
];
export default class Irv {
  sendUserResponse;
  handleAge;
  isCCRCIntent;
  handleCCRC;
  constructInsuranceList;
  resetBot;
  beepAudio;
  AudioContext;
  constructor() {
    window.recorderEndIos = this.recorderEndIos;
    window.speechToTextUrl = Urls.speechToText;
    this.AudioContext =  window.AudioContext || window.webkitAudioContext;
    if(this.AudioContext){
     this.beepAudio = this.AudioContext ? new this.AudioContext() : undefined;
    }
  }

  playAudio = () => {
    // To play the audio received from Google speech synthesis
    const stateInstance = State.getInstance();
    let text = stateInstance.convJson.bot;
    let intentName = stateInstance.convJson.intentName;
    let audioIntent = "";

    currentIntentName = intentName;
    currentBotMessage = text;

    if (this.isAudioPaused() && audioIntent === intentName) {
      const audioEle = document.getElementById("audioResponse");
      const promise = audioEle.play();
      if (promise !== undefined) {
        promise
          .catch((error) => {
            console.log("error---------", error);
          })
          .then(() => {
            console.log("playingaudio");
          });
      }
      return;
    }
    text = this.processTextForAudio();
    this.sendTextToSpeechRequest(text);
    return true;
  };

  processTextForAudio() {
    // Get the text from dialogflow and send google to get audio
    let stateInstance = State.getInstance();
    let text = stateInstance.convJson.bot;
    let suggestions = stateInstance.convJson.suggestions;
    let inputType = stateInstance.convJson.inputType;
    let intentName = stateInstance.convJson.intentName;

  

    text = text.split("<br>").join("");
    text = text.replace("<FAILURE>", "");
    text = text.replace("#SUCCESS#", "");
    text = text.replace("#Success#", "");
    text = text.replace("#ERROR#", "");
    text = text.replace("#Error#", "");

    text = text
      .split(`<${textOnlyTagName}>`)
      .map((t) => {
        if (t.indexOf(`</${textOnlyTagName}>`) > -1) {
          return t.substr(t.indexOf(`</${textOnlyTagName}>`) + 7);
        }
        return t;
      })
      .join("");

    text = this.replaceLinksInText(text);
    text = this.removeEmojis(text);

    text = text.replace(":-)", "");
    // text = text.replace(";-)", "");

    if (suggestions && !text.includes(emailContent)) {
      text += "\n" + this.getSuggestionText(suggestions);
    }

    if (intentName === dobIntentName) {
      text +=
        ". For example, if your date of birth is 1st of April 1956, then state it as 'one April 1956'.";
    }

    if (currentBotMessage.includes(spouseDOBContent)) {
      text +=
        ". For example, if your date of birth is 1st of April 1956, then state it as 'one April 1956'.";
    }

    if (intentName === welcomeIntent) {
      text += "or type it on the screen.";
    }


    return text;
  }

  replaceLinksInText(text) {
    // Some text have links which should not be in the audio
    return text
      .split(`<${linkTag}`)
      .map((t) => {
        if (t.indexOf(`</${linkTag}>`) > -1) {
          // console.log(t);
          let linkText = t.substr(
            t.indexOf(">") + 1,
            t.indexOf(`</${linkTag}>`) - t.indexOf(">") - 1
          );
          return `${linkText} ${t.substr(t.indexOf(`</${linkTag}>`) + 4)}`;
          // return linkReplaceText + ' ' + t.substr(t.indexOf(`</${linkTag}>`)+4);
        }
        return t;
      })
      .join("");
  }

  isYesorNoSuggestion(suggestions) {
    // Check the suggestion chips
    if (
      suggestions.length === 2 &&
      (suggestions[0] === "Yes" ||
        suggestions[0] === "yes" ||
        suggestions[0] === "No" ||
        suggestions[0] === "no")
    ) {
      return true;
    } else {
      return false;
    }
  }

  getSuggestionText(suggestions) {
    // Process suggestions to form text for audio
    if (this.isYesorNoSuggestion(suggestions)) {
      return "";
    }

    const stateInstance = State.getInstance();
    const suggestionsToDisable = stateInstance.suggestionsToDisable;
    if (suggestionsToDisable && suggestionsToDisable.length) {
      suggestions = suggestions.filter(
        (s) => !suggestionsToDisable.includes(s)
      );
    }

    return suggestions
      .map((t, i) => {
        let optionText = t === moreId ? "to know about other options" : t;
        return `Say ${i + 1} if you want to choose ${optionText}.`;
      })
      .join(" ");
  }

  removeEmojis(string) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    return string.replace(regex, "");
  }

  sendTextToSpeechRequest(text) {
    // send text to speech
    let data = { text: text };

    let stateInstance = State.getInstance();
    let endOfConv = stateInstance.endOfConv;
    let endRecorder = stateInstance.endRecorder;
    let suggestions = stateInstance.convJson.suggestions;
    let intentName = stateInstance.convJson.intentName;

    let audioEndCallback = () => {
      this.removeAudioAnimation();

      if (
        !endOfConv &&
        !endRecorder &&
        intentName !== resetIntentName &&
        intentName !== backIntentName &&
        this.isAudioEnabled() &&
        !currentBotMessage.includes(emailContent)
      ) {
        this.startRecorder(suggestions, intentName, false);
      }

      if (intentName === resetIntentName) {
        this.resetBot();
        return;
      }

      if (intentName === backIntentName) {
        this.clickLastEditButton();
        return;
      }
    };
    this.dummy();
    slowLoad = setTimeout(() => {
      console.log("Slow internet detected..");
      // disableIVRFeature();
    }, TIME_TO_WAIT_FOR_AUDIO);
    axios.post(Urls.textToSpeechUrl, data).then((response) => {
      window.clearTimeout(slowLoad);
      this.playAudioBuffer(response.data.audio.data, audioEndCallback);
    });
  }

  playSuggestions = () => {
    // Play suggestion audio
    let stateInstance = State.getInstance();
    let text = this.getSuggestionText(stateInstance.convJson.suggestions);
    this.playSuggestionTextAudio(text, stateInstance.convJson.suggestions);
  };

  playSuggestionTextAudio(text, suggestions, intentName, timeout) {
    // Play suggestion text audio
    let data = { text };
    let audioEndCallback = () => {
      this.removeAudioAnimation();

      if (
        intentName !== resetIntentName &&
        intentName !== backIntentName &&
        this.isAudioEnabled() &&
        !currentBotMessage.includes(emailContent)
      ) {
        this.startRecorder(suggestions, intentName, false);
      }

      if (intentName === resetIntentName) {
        this.resetBot();
        return;
      }

      if (intentName === backIntentName) {
        this.clickLastEditButton();
        return;
      }
    };
    axios.post(Urls.textToSpeechUrl, data).then((response) => {
      this.playAudioBuffer(response.data.audio.data, audioEndCallback);
    });
  }

  playAudioBuffer(data, audioEndCallback) {
    if (!this.isAudioEnabled()) {
      return;
    }
    const audio = this.getAudioBuffer(data, audioEndCallback);
    this.addAudioAnimation();
    audio.play();
  }

  // Animation of mic in the UI
  addAudioAnimation() {
    this.removeAudioAnimation();
    let animationElements = this.getKeikoAudioAnimElement();
    let lastEle = document.getElementsByClassName(
      `${keiko_avatar_parent_class}`
    );
    lastEle[lastEle.length - 1].innerHTML += animationElements;
    // $(`.${keiko_avatar_parent_class}:last`).append(animationElements);
  }

  removeAudioAnimation() {
    let animationElement = document.getElementsByClassName(
      `${keiko_animation_class}`
    );
    if (animationElement.length > 0) {
      animationElement[0].remove();
    }
  }

  getKeikoAudioAnimElement() {
    return `<div class="keiko-animate">
            ${Array(10).fill('<div class="bar"></div>').join("")}
          </div>`;
  }

  getAudioBuffer(data, audioEndCallback) {
    var uInt8Array = new Uint8Array(data);
    var arrayBuffer = uInt8Array.buffer;
    // var blob = new Blob([arrayBuffer]);
    var blob = new Blob([arrayBuffer], { type: "audio/mpeg" });

    var url = URL.createObjectURL(blob);
    let audioResponse = document.getElementById("audioResponse");
    audioResponse.src = url;
    // audioResponse.removeEventListener("ended");
    audioResponse.onended = null;
    audioResponse.onended = audioEndCallback;
    return audioResponse;
  }

  startRecorder(suggestions, intentName, timeout) {
    if (
      !(
        window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition
      )
    ) {
      this.startRecorderIos(suggestions, intentName);
      return;
    }
    recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition ||
      window.mozSpeechRecognition ||
      window.msSpeechRecognition)();
    recognition.lang = "en-US";
    recognition.interimResults = false;
    recognition.maxAlternatives = 5;
    recognition.start();
    this.showListening();
    resultReceived = false;

    if (!timeout) {
      this.beep();
    }

    recognition.onresult = (event) => {
      let text = event.results[0][0].transcript;
      this.recorderEnd(text, suggestions, intentName, timeout);
    };

    setTimeout(() => {
      this.stopListening(resultReceived !== true);

      if (resultReceived === false && this.isAudioEnabled()) {
        this.startRecorder(suggestions, intentName, true);
      }
    }, 5000);
  }

  startRecorderIos(suggestions, intentName) {
    console.log("recorder ios..");

    // beep();

    let constraints = {
      audio: true,
      video: false,
    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      let options = {};
      let speechEvents = hark(stream, options);

      let startButton = document.getElementById("start-btn");
      startButton.click();
      this.showListening();
      let recorderStartTime = new Date();

      setTimeout(() => {
        if (recorderStartTime !== null) {
          this.stopRecorderIos();
          recorderStartTime = null;
        }
      }, 5000);

      speechEvents.on("stopped_speaking", () => {
        let stoppedSpeakingTime = new Date();
        if (recorderStartTime === null) {
          return;
        }
        let difference =
          stoppedSpeakingTime.getTime() - recorderStartTime.getTime();

        const minTimeDifference = 3000; // in milliseconds

        if (difference > minTimeDifference) {
          recorderStartTime = null;
          this.stopRecorderIos();
        } else {
          setTimeout(() => {
            this.stopRecorderIos();
          }, minTimeDifference);
        }

        setTimeout(() => {
          const track = stream.getTracks()[0]; // if only one media track
          track.stop();
        }, 100);
      });
    });
  }

  stopRecorderIos() {
    // Stop the recording by triggering button click
    let stopButton = document.getElementById("stop-btn");
    if(stopButton){
    stopButton.click();
    this.stopRecorderSymbol();}
  }

  recorderEndIos(text) {
    // After the recorder ends in ios. it is called from html
    let stateInstance = State.getInstance();
    let suggestions = stateInstance.convJson.suggestions;
    let intentName = stateInstance.convJson.intentName;
    this.recorderEnd(text, suggestions, intentName, false);
  }

  recorderEnd(text, suggestions, intentName, timeout) {
    resultReceived = true;
    text = text.charAt(0).toUpperCase() + text.slice(1);

    const stateInstance = State.getInstance();
    const inputType = stateInstance.convJson.inputType;

    if (
      suggestions !== null &&
      suggestions !== undefined &&
      suggestions !== "undefined" &&
      inputType !== "multiselect" &&
      !this.isYesorNoSuggestion(suggestions)
    ) {
      let number = parseInt(text);
      if (typeof suggestions === "string") {
        suggestions = suggestions.split(",");
      }
      text = number > 0 ? suggestions[number - 1] : text;

      if (linkSuggestionMap[text.toLowerCase()]) {
        window.open(linkSuggestionMap[text.toLowerCase()], "_blank");
        return;
      }
      if (number > suggestions.length) {
        text = suggestions[0];
      }
      if (isNaN(number)) {
        number = this.convertToRhymingNumber(text);
        if (isNaN(number)) {
          let text = this.getSuggestionText(suggestions);
          this.playSuggestionTextAudio(text, suggestions, intentName, timeout);
          return;
        }
        text = suggestions[number - 1];
      }
      if (text === moreId) {
        this.showMorePrivateInsuraces();
        suggestions = stateInstance.convJson.suggestions;
        let text = this.getSuggestionText(suggestions);
        this.playSuggestionTextAudio(text, suggestions, intentName, timeout);
        return;
      }
    }

    if (
      suggestions !== null &&
      suggestions !== undefined &&
      suggestions !== "undefined" &&
      inputType === "multiselect" &&
      !this.isYesorNoSuggestion(suggestions)
    ) {
      const numbers = this.parseMultipleOptions(text);
      const options = numbers
        .map((n) => suggestions[n])
        .filter((n) => n !== undefined);
      const multiselect = true;
      this.sendUserResponse(options, multiselect, true);
      return;
    }

    if (intentName === dependantsIntent) {
      let number = parseInt(text);
      if (Number.isNaN(number)) {
        // startRecorder(suggestions, intentName);
        return;
      }
      text = number.toString();
    }

    let selectStateEle = document.querySelector(".select-state-btn");
    if (selectStateEle) {
      stateInstance.stateJson.state = text;
    }

    let inputEle = document.getElementById("user-input");
    if (inputEle) {
      if (inputEle.parentElement.style.display !== "none") {
        inputEle.value = text;
        let sendButton = document.getElementById("send-button");
        sendButton.click();
        return;
      }
    } else if (
      (intentName === dobIntentName ||
        currentBotMessage.includes(spouseDOBContent)) &&
      text.indexOf("remember") < 0
    ) {
      let [date, month_text, year] = text.split(" ");
      let month = dp_month_array.indexOf(month_text);
      if (month < 0) {
        month = dp_month_array_full.indexOf(month_text);
      }
      month += 1;
      if (isNaN(month) || isNaN(date) || isNaN(year)) {
        return;
      }
      this.handleAge(month, date, year);
      return;
      // } else if (!(suggestions !== null && suggestions !== undefined && suggestions !== "undefined")) {
      //   if (!(text.toLowerCase() == 'yes' || text.toLowerCase() == 'no' )) {
      //     return;
      //   }
    }
    if (this.isCCRCIntent(stateInstance.convJson.intentName)) {
      this.handleCCRC(stateInstance.convJson.intentName, text);
      return;
    }
    this.sendUserResponse(text);
  }

  showMorePrivateInsuraces() {
    this.constructInsuranceList();
  }

  parseMultipleOptions(text) {

    let numbers = [];
    text = text.trim();
    for (let index = 0; index < rhymingNumbers.length; index++) {
      const rhyNumber = rhymingNumbers[index];
      for (let i = 0; i < rhyNumber.length; i++) {
        const word = rhyNumber[i];
        text = text.replace(`/${word}/g`, index);
      }
    }
    let letters = text.split("");
    numbers = letters
      .map((l) => Number(l) - 1)
      .filter((n) => isNaN(n) === false && n >= 0);

    return numbers;
  }

  convertToRhymingNumber(text) {
    text = text.toLowerCase();
    for (let index = 0; index < rhymingNumbers.length; index++) {
      const rNum = rhymingNumbers[index];
      const found = rNum.find((r) => r === text);
      if (found) {
        return index + 1;
      }
    }
  }

  isAudioEnabled() {
    let element = document.getElementById("ivr");
    return (
      element.classList.contains("mic-listen") ||
      element.classList.contains("mic-on")
    );
  }

  showListening() {
    document.getElementById("ivr").className = "";
    document.getElementById("ivr").classList.add("mic-listen");
    document.getElementById("ivr").children[0].style.display = "block";
    document.getElementsByClassName(".mic-listen").onclick = this.stopListening;
  }

  stopListening = (isTimeout) => {
    this.stopRecorderSymbol();
    if (recognition !== undefined && recognition.abort !== undefined) {
      recognition.abort();
    }
    if (
      !(
        window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition
      )
    ) {
      window.abortedRecording = true;
      this.stopRecorderIos();
    }
    resultReceived = isTimeout === true ? false : true;
  };

  stopRecorderSymbol() {
    if (document.getElementById("ivr")) {
      if (
        document.getElementById("ivr").classList.contains("mic-on") ||
        document.getElementById("ivr").childElementCount
      ) {
        document.getElementById("ivr").children[0].style.display = "none";
      }
      if (document.getElementById("ivr").classList.contains("mic-listen")) {
        document.getElementById("ivr").children[0].style.display = "none";
        document.getElementById("ivr").className = "";
        document.getElementById("ivr").classList.add("mic-listen");
      }
    }
  }

  dummy() {
    let audioResponse = document.getElementById("audioResponse");
    audioResponse.src = "silent.mp3";
    audioResponse.onended = null;
    audioResponse.play();
    return audioResponse;
  }

  clickLastEditButton() {
    let editButtons = document.getElementsByClassName("change-answer");
    if (editButtons.length) {
      let lastButton = editButtons[editButtons.length - 2];
      if (lastButton && lastButton.click) {
        lastButton.click();
      }
    }
  }

  isAudioPaused() {
    const audioEle = document.getElementById("audioResponse");
    return audioEle.paused;
  }

  beep() {
    if (!this.beepAudio) {
      return;
    }
    let v = this.beepAudio.createOscillator();
    let u = this.beepAudio.createGain();
    u.gain.value = 0.1; // setting it to 10%
    // v.connect(this.beepAudio.destination);
    v.connect(u);
    v.type = "square";
    u.connect(this.beepAudio.destination);
    v.start(this.beepAudio.currentTime);
    v.stop(this.beepAudio.currentTime + 0.1);
  }

  stopAudio = () => {
    const audioEle = document.getElementById("audioResponse");
    if (audioEle && audioEle.duration > 0 && !audioEle.paused) {
      audioEle.pause();
    }
    this.removeAudioAnimation();
  };

  registerCallbacks(
    sendUserResponse,
    handleAge,
    isCCRCIntent,
    handleCCRC,
    constructInsuranceList,
    resetBot
  ) {
    this.sendUserResponse = sendUserResponse;
    this.handleAge = handleAge;
    this.isCCRCIntent = isCCRCIntent;
    this.handleCCRC = handleCCRC;
    this.constructInsuranceList = constructInsuranceList;
    this.resetBot = resetBot;
  }

  static getInstance() {
    if (this.ivr_instance) {
      return this.ivr_instance;
    } else {
      this.ivr_instance = new Irv();
      return this.ivr_instance;
    }
  }
}
