export const loginPath = "/login";
export const signupPath = "/signup";
export const termsPath = "/terms";
export const rolePath = "/role";
export const mainPath = "/main";
export const answerPath = "/answer";
export const resetPath = "/reset";
export const advisorResPath = "/advisor-res";
export const advisorMMPath = "/advisor-mm";
export const elderCarePath = "/elderCare";
export const summaryPath = "/summary";
export const patientInfo = "/patient-info";
export const patientTest = "/patient-test";
export const patientSuccess = "/patient-success";
export const patientView = "/patient-view";
export const systemError ='/system-error'
export const clinicianProfile = '/clinician-profile'
export const careGiverProfile = '/caregiver-profile'
export const patientProfile = '/patient-profile';
export const communitySignUp = '/community-signup';
export const stressScore = '/stress-score';
export const contactus = '/contact';
export const burnOut = '/burn-out';
export const phoneAuth = '/phone-auth';
export const stressWelcome = '/stress-welcome';
export const selection = '/selection';
export const dementiaScreening = '/dem-screening';
export const careCoach = '/care-coach';
export const tracker= '/mypractice';
export const trackerReport = '/tracker-report';