import firebase from "firebase/app";
import * as Config from "../config/app.config";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage'
import 'firebase/messaging';
import axios from "axios";

const accessCode = require("../config/json/accessCode.json")

class Firebase {
  db;
  usersCollectionRef;
  currentUser;
  constructor() {
    firebase.initializeApp(Config.firebase);
    this.db = firebase.firestore();
    this.usersCollectionRef = this.db.collection("users");
    this.currentUser = "";
    this.firebase = firebase;
    this.messaging = firebase.messaging.isSupported() ? firebase.messaging(): null
    this.storage = firebase.storage();
    this.auth = new window.auth0.WebAuth(Config.authO);
  }
  
  isEmailAllowed = async (email, redirect, name, showMessage, type ,uid) => {
    axios
      .get(`/fhir/enrollment?email=${email}`)
      .then(async (response) => {
        if (response.data) {
          await this.insertUserActivityData(email.toLowerCase(),'login',{
            loggedInAt: new Date(),
          })
          const supportEmail = response.data.country === 'India'? 'support@amicalisbrain.com': 'support@amicusbrain.com';
          this.updateUserInfoData(email.toLowerCase(), {
            signedIn: true,
            forceLogout:false
          }).then( async () => {
            if(type === 'Signup'){
           await axios
            .post('/fhir/email/install',{
              firstName: name,
              lastName: '',
              email: email.toLowerCase(), 
              supportEmail: supportEmail
            })
          }         
          this.auth.login(
            {
              realm: Config.authO.db,
              email: email.toLowerCase(),
              password: uid,
            }, (err)=> {
              if (err && err.code === 'access_denied') {
                this.auth.signup({
                  connection: Config.authO.db,
                  email: email.toLowerCase(),
                  password: uid,
                }, (err)=> {
                  if(!err){
                  this.auth.login(
                    {
                      realm:Config.authO.db,
                      email: email.toLowerCase(),
                      password:uid
                    }, function (err) {
                      window.location = redirect
                    }
                  ) 
                  } 
                  else {
                    window.location = redirect
                  }
                });
              } else {
                window.location = redirect
              }
            }
          );

          });
        } else {
          showMessage()
          this.logout();
        }
      })
      .catch((err) => {
        this.logout("/login");
      });
  };


  login = (formData, redirect, errorHandler) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(formData.email, formData.password)
      .then( async () => {
        await this.updateUserInfoData(formData.email.toLowerCase(), {
          forceLogout:false
        })
        await this.insertUserActivityData(formData.email.toLowerCase(), 'login', {
          'loggedInAt': new Date(),
        }).then(() => {
          this.auth.login(
            {
              realm: Config.authO.db,
              email: formData.email.toLowerCase(),
              password: formData.password,
            }, (err)=> {
              if (err && err.code === 'access_denied') {
                this.auth.signup({
                  connection: Config.authO.db,
                  email: formData.email.toLowerCase(),
                  password: formData.password,
                }, async (err)=> {
                  if(!err){
                  this.auth.login(
                    {
                      realm:Config.authO.db,
                      email:  formData.email.toLowerCase(),
                      password: formData.password,
                    }, function (err) {
                      window.location = redirect
                    }
                  ) 
                  } 
                  else if(err && err.code === "invalid_signup"){
                    try {
                      const data = await axios.post('/fhir/circle/token',{
                        "client_id":"sY4gOiOJAG8au5UUj5FSygQcGLEBHZ1N","client_secret":"H8wID3NclQFTdnWt52HLNI-hDSPChSauCs_nRx0Pxv0a7vrCzamSyl2i_w-sWqoV"
                      });
                      if(data.data){
                        const token = data.data.access_token;
                        const userData = await axios.get(`/fhir/circle/user?email=${formData.email.toLowerCase()}`, {
                          headers: {
                            token: 'Bearer ' +token
                          }
                        });
                        if(userData.data && userData.data[0] && userData.data[0].identities[0].user_id){
                          await axios.put('/fhir/circle/user',{
                            id: userData.data[0].identities[0].user_id,
                            password: formData.password,
                            connection: Config.authO.db,
                          },{
                            headers: {
                              token: 'Bearer ' +token
                            }
                          })
                          this.auth.login(
                            {
                              realm:Config.authO.db,
                              email:  formData.email.toLowerCase(),
                              password: formData.password,
                            }, function (err) {
                              window.location = redirect
                            }
                          ) 
                        }
                      }
                    } catch (error) {
                   window.location = redirect
                    }
                  }
                });
              } else {
                window.location = redirect
              }
            }
          );
        }).catch((err) => {
          window.location = redirect
        })

      })
      .catch(errorHandler);
  };
  signInWithGoogle = () => {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    firebase.auth().useDeviceLanguage();
    firebase.auth().signInWithRedirect(provider);
  };

  signup = (formData, redirect, errorHandler, supportEmail, callback, code) => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(formData.email, formData.password)
      .then((userDetails) => {
        userDetails.user
          .updateProfile({
            displayName: formData.name,
          })
          .then(async () => {
            const data = await axios.post('/api/auth', {
              "email": formData.email.toLowerCase(), ...Config.oAuth
            })
            sessionStorage.setItem('token', data.data.token)
            if(code && accessCode[code.toLowerCase()] && accessCode[code.toLowerCase()].spaceId) {
              const [firstName, lastName]= formData.name.split(" ")
              const url = `https://app.circle.so/api/v1/community_members?email=${formData.email.toLowerCase()}&name=${firstName+" "+lastName?lastName:''}&community_id=83952&space_ids[]=${accessCode[code.toLowerCase()].spaceId}&skip_invitation=true`             
              await axios.post('/fhir/community/group/invite', {url: url})
            }
            this.updateUserInfoData(formData.email.toLowerCase(), {
              signedIn: true,
              forceLogout:false
            }).then(() => {
              this.installEmail(formData, callback,redirect,supportEmail)
              this.insertUserActivityData(formData.email.toLowerCase(), 'login', {
                loggedInAt: new Date(),
              }).then()
              this.auth.signup({
                connection: Config.authO.db,
                email: formData.email.toLowerCase(),
                password: formData.password,
                name: formData.name
              }, (err) => {
                this.auth.login(
                  {
                    realm: Config.authO.db,
                    email:  formData.email.toLowerCase(),
                    password: formData.password,
                  }, function (err) {
                   
                  }
                ) 
              });
            });
          })
          .catch((e) => {
            //window.location = redirect
          });
      })
      .catch(errorHandler);
  };



  installEmail = (formData,callback,redirect,supportEmail) => {
    axios
    .post('/fhir/email/install', {
      firstName: formData.name,
      lastName: '',
      email: formData.email.toLowerCase(),
      supportEmail: supportEmail
    })
    .then(() => {})
  }

  logout = (redirect) => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        if(window.circleTab.length){
          window.circleTab.forEach((tab)=>{
            if(typeof tab === 'object' && tab !== null)
            tab.close()
          })
        }
        if(redirect)
        window.location = redirect;
        localStorage.setItem("uid", null);
      })
      .catch(function (error) {
        if(redirect)
        window.location = redirect;
        console.error(error);
      });
  };

  getUserInfo = () => {
    return this.db.collection("Enrollment").get();
  };

  getTopicsInfo = (docs) => {
    return this.db.collection("topics").doc(docs);
  };


  insertUserInfoData = (user, data) => {
    return this.db.collection("Enrollment").doc(user).set(data);
  };

  updateUserInfoData = (user, data) => {
    return this.db.collection("Enrollment").doc(user).update(data);
  };

  updateUserMasterInteraction = (user, data) => {
    return this.db.collection("user-interaction").doc(user).set(data, { merge: true });
  };    

  insertUserActivityData = (user, field, data) => {
    return this.db.collection("user-activity").doc(user).set({[`${field}`]: firebase.firestore.FieldValue.arrayUnion(data)}, { merge: true });
  }

  insertUserFavRaList = (user,field,data) => {
    return this.db.collection("user-fav-list").doc(user).set({[`${field}`]: firebase.firestore.FieldValue.arrayUnion(data)}, { merge: true });
  }

  insertUserNotification = (user,field,data) => {
    return this.db.collection("Enrollment").doc(user).set({[`${field}`]: firebase.firestore.FieldValue.arrayUnion(data)}, { merge: true });
  }
  
  insertIntentData = (user,field,data) => {
    return this.db.collection("user-intents").doc(user).set({[`${field}`]: firebase.firestore.FieldValue.arrayUnion(data)}, { merge: true });
  }

  insertIntentDataFeedBack = (user,field,data) => {
    return this.db.collection("user-intent-interaction").doc(user).set({[`${field}`]: firebase.firestore.FieldValue.arrayUnion(data)}, { merge: true });
  }

  removedUserFavRaList = (user,field,data) => {
    return this.db.collection("user-fav-list").doc(user).update({[`${field}`]: firebase.firestore.FieldValue.arrayRemove(data)});
 
  }

  getUserFavRaList = (user) => {
    return this.db.collection("user-fav-list").doc(user);
  }

  getCondition = () => {
    return this.db.collection("Conditions").get()
  }

  getTrustedSite = () => {
    return this.db.collection("Trusted-Sites").get()
  }

  setCondition = (data) => {
    return this.db.collection("Conditions").doc('medical-conditions').set(data, { merge: true });
  }

  getUserMasterInteraction = (user) => {
    return this.db.collection("user-interaction").doc(user);
  }

  deleteUserInfoData = (user) => {
    return this.db.collection("Enrollment").doc(user).delete();
  };

  checkIfUserLoggedIn = (callback) => {
    firebase.auth().onAuthStateChanged((user) => {
      this.currentUser = user;
      if (callback) {
        callback(user);
      }
    });
  };

  checkIfFirebaseUserLoggedIn = () => {
    return firebase.auth().this.currentUser;
  };

  resetPassword = (email, successHandler, errorHandler) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(successHandler)
      .catch(errorHandler);
  };

  initUserData = (uid) => {
    this.usersCollectionRef.doc(this.currentUser.uid).set({});
  };

  getUserData = (uid) => {
    uid = uid ? uid : this.currentUser.uid;
    return this.usersCollectionRef.doc(uid).get();
  };

  setUserData = async (data, key) => {
    let json = {};
    json[key] = JSON.stringify(data);
    let doc = await this.getUserData(this.currentUser.uid);
    if (doc.exists) {
      if (key) {
        return this.usersCollectionRef.doc(this.currentUser.uid).update(json);
      }
    } else {
      if (key) {
        return this.usersCollectionRef.doc(this.currentUser.uid).set(json);
      }
    }
  };

  handleCallback = async (code,name,redirect,email,uid) => {
    const [firstName, lastName] = name && name.split(" ");
    const payload = accessCode[code.toLowerCase()];
    payload.firstName = firstName ? firstName : "";
    payload.lastName = lastName ? lastName : "";
    payload.sDate = new Date();
    const d = new Date();
    d.setMonth(d.getMonth() + 1);
    payload.eDate = d;
    const dataExist =  await this.db.collection('Enrollment').doc(email.toLowerCase()).get();
    if(!dataExist.exists){
    this
      .insertUserInfoData(email.toLowerCase(), payload)
      .then((data) => {
        sessionStorage.setItem('code','')
      })
      .catch((err) => {
        console.error(err);
      });
    } else {
      sessionStorage.setItem('code','')
    }
    this.auth.login(
      {
        realm: Config.authO.db,
        email: email.toLowerCase(),
        password: uid,
      }, (err)=> {
        if (err && err.code === 'access_denied') {
          this.auth.signup({
            connection: Config.authO.db,
            email: email.toLowerCase(),
            password: uid,
          }, (err)=> {
            if(!err){
            this.auth.login(
              {
                realm:Config.authO.db,
                email: email.toLowerCase(),
                password:uid
              }, function (err) {
                window.location = redirect
              }
            ) 
            } 
            else {
              window.location = redirect
            }
          });
        } else {
          window.location = redirect
        }
      }
    );
  }

  getUserDataFromFhir = (email) => {
    return axios.get("/fhir/user?email=" + email, {
      headers: { uid: localStorage.getItem("uid") },
    });
  };


  redirectAfterLogin =  (redirect, showLoader, showMessage,type) => {
    showLoader(true);
    firebase
      .auth()
      .getRedirectResult()
      .then(async (result) => {
        if (result.credential) {
          // var token = result.credential.accessToken;
          const data = await axios.post('/api/auth', {
            "email": result.user?.email.toLowerCase(), ...Config.oAuth
          })
          sessionStorage.setItem('token', data.data.token)
          if(sessionStorage.getItem('code') && accessCode[sessionStorage.getItem('code').toLowerCase()] && accessCode[sessionStorage.getItem('code').toLowerCase()].spaceId) {
            const url = `https://app.circle.so/api/v1/community_members?email=${result.user?.email.toLowerCase()}&name=${result.user?.displayName}&community_id=83952&space_ids[]=${accessCode[sessionStorage.getItem('code').toLowerCase()].spaceId}&skip_invitation=true`             
            await axios.post('/fhir/community/group/invite', {url: url})
          }
          var email = result.user?.email;
          localStorage.setItem("userName", result.user?.displayName);
          if (sessionStorage.getItem('code') && (sessionStorage.getItem('code').toLowerCase() in accessCode)) {
            this.handleCallback(sessionStorage.getItem('code'), result.user?.displayName, redirect, email.toLowerCase(),type,result.user?.uid)
          } else {
            this.isEmailAllowed(email.toLowerCase(), redirect, result.user?.displayName, showMessage,type,result.user?.uid);
          }
        } else {
          showLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default Firebase;
