import React from "react";

const FirebaseContext = React.createContext(null);
export const StateContext  = React.createContext(null);

export default FirebaseContext;


export const withFireBase = (Component) => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export const withStateAndFireBase = (Component) => (props) => (
  <StateContext.Consumer>
    {(state) => (
      <FirebaseContext.Consumer>
        {(firebase) => (
          <Component {...props} firebase={firebase} globalState={state} />
        )}
      </FirebaseContext.Consumer>
    )}
  </StateContext.Consumer>
);
