import React, { Suspense, useEffect, useContext, useState } from "react";
import "./App.scss";
import * as RouteConstant from "../src/contants/routeContants";
import {notifications} from "../src/contants/contants"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { withFireBase } from "./context-store";
import Button from "@material-ui/core/Button";
import { Util } from "./utils/util";
import { useTranslation } from "react-i18next";
import * as Config from "./config/app.config";
import {
  appplicationTimeOut,
  appplicationTimeOutClincian,
  dfTimeout,
  firebase
} from "./config/app.config";
import State from "./state/state";
import { ReduxContext } from "./context-store/redux-context";
import axios from "axios";
import Spinner from "./components/spinner";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Snackbar from "./components/snackbars"
import { getCareGiverPatientList } from './utils/fhir';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import "./utils/timelapse"

const THEME = createMuiTheme({
  typography: {
    fontFamily: `"NunitoSans"`,
  },
  palette: {
    primary: {
      light: "#0066cc",
      main: "#0066cc",
      dark: "#0066cc",
      contrastText: "#ffffff",
    },
  },
});

const states = State.getInstance();
const Login = React.lazy(() => import("../src/pages/login/login"));
const Signup = React.lazy(() => import("../src/pages/signup/signup"));
const Terms = React.lazy(() => import("../src/pages/terms/terms"));
const Role = React.lazy(() => import("../src/pages/role/role"));
const Main = React.lazy(() => import("../src/pages/main/main"));
const Answer = React.lazy(() => import("../src/pages/answers/answer"));
const Reset = React.lazy(() => import("../src/pages/reset/reset"));
const LandingPage = React.lazy(() => import("../src/components/landing-page"));
const Summary = React.lazy(() => import("../src/pages/summary/summary"));
const ElderCare = React.lazy(() => import("../src/pages/elderCare/elderCare"));
const Chat = React.lazy(() => import("../src/pages/chat/chat"));
const PatientInfo = React.lazy(() =>
  import("../src/pages/patientInfo/patientInfo")
);
const PatientTest = React.lazy(() =>
  import("../src/pages/patientTest/patientTest")
);
const PatientTestSuccess = React.lazy(() =>
  import("../src/pages/patientTest/patientTestSuccess")
);
const PatientView = React.lazy(() =>
  import("../src/pages/patientView/patientView")
);
const Error = React.lazy(() => import("../src/pages/error/error"));
const Wrong = React.lazy(() => import("../src/pages/error/somethingWrong"));
const Cprofile = React.lazy(() => import("../src/pages/clinicianProfile/ClinicianProfile"))
const CareProfile = React.lazy(() => import("../src/pages/careGiverProfile/CareGiverProfile"))
const PatProfile = React.lazy(() => import("../src/pages/patientProfile/PatientProfile"))
const TInfo = React.lazy(() => import("../src/pages/testInfo/test-info"))
const Admin = React.lazy(() => import("../src/pages/admin/admin"))
const Help = React.lazy(() => import("../src/pages/docs/docs"));
const CommunitySignUp = React.lazy(() => import("../src/pages/communitySignUp/communitysignup"));
const StressScore = React.lazy(() => import("../src/pages/stressScore/stressScore"));
const Render = React.lazy(() => import("../src/pages/render/render"));
const Contact = React.lazy(() => import("../src/pages/docs/contact"))
const StressWelcome = React.lazy(() => import("../src/pages/stressScore/stressWelcome"))
const PhoneAuth = React.lazy(() => import("../src/pages/phoneAuth/phoneAuth"))
const Jobs = React.lazy(() => import("../src/pages/admin/jobs"))
const Selection = React.lazy(() => import("../src/pages/stressScore/selection"))
const CareCoach = React.lazy(() => import("../src/pages/carecoach/carecoach"));
const ActivityTracker = React.lazy(() => import("./pages/activityTracker/activityTracker"))
const Batch = React.lazy(() => import("../src/pages/admin/batch"));
const ActivityTrackerReport = React.lazy(() => import("../src/pages/activityTracker/activityTrackerReport"))
const RASms = React.lazy(() => import("../src/components/ra-sms-result"));
const FBlogin = React.lazy(()=> import("../src/pages/facebook/login"));
const FBresponse = React.lazy(()=> import('../src/pages/facebook/response'))
const Profile = React.lazy(() => import('../src/pages/profile/profile'))
let unsubscribe;

const skipUrl = ['/system-error','/login','/test-info','/render','/contact','/facebook']

function App(props) {
  const [showScreen, setShowScreen] = useState(false);
  const [state, dispatch] = useContext(ReduxContext);
  const [showNotification, setShowNotification] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const { i18n, t } = useTranslation();
  useEffect(() => {
    if (!skipUrl.includes(window.location.pathname)) {
      props.firebase.checkIfUserLoggedIn(async (user) => {
        if(!user) {
          if(window.location.pathname=== '/ra')
          {
            window.localStorage.setItem('redirectUrl', window.location.pathname+window.location.search);
          }
          window.location.href = "/login"
          return
        }
        const data = await axios.post('/api/auth', {
          "email": user.email.toLowerCase(), ...Config.oAuth
        })
        sessionStorage.setItem('token', data.data.token);
   
        if (user && user.displayName) {
          if ('Notification' in window && !window.localStorage.token && (!(window.location.pathname.includes('system-error') || window.location.pathname.includes('login') || window.location.pathname.includes('test-info') || window.location.pathname.includes('signup') ))) {
            setShowNotification(true)
          }
          localStorage.setItem("uid", user.uid);
          localStorage.setItem("userName", user.displayName);
          localStorage.setItem("gSign", user.providerData[0]?.providerId)
          sessionStorage.setItem("email", user.email);
          axios
            .all([getUserData(user.email), getEnrollment(user.email)])
            .then((doc) => {
              if (doc[0].data && doc[0].data.email) {
                unsubscribe = props.firebase.db.collection("Enrollment").doc(doc[0].data.email).onSnapshot(async (docs) => {
                  if (docs.exists) {
                    if(docs.data().forceLogout) {
                      await props.firebase.updateUserInfoData(doc[0].data.email, {forceLogout: false})
                      props.firebase.logout("/login");
                    }
                    if (docs.data().isSuspended) {
                      props.firebase.logout("/login");
                    }
                    if(docs.data().isCommunityLoggedIn){
                      if(!states.isCommunityLoggedIn){
                        states.isCommunityLoggedIn = docs.data().isCommunityLoggedIn
                      dispatch({
                        type: "ADD_COMMUNITY_DATA",
                        payload: { isCommunityLoggedIn: docs.data().isCommunityLoggedIn}
                      });
                    }
                  }
                  }
                })
              }            
              if (doc[0].data) {
                dispatch({
                  type: "ADD_FHIR",
                  payload: doc[0].data,
                });
                if(doc[0].data.prefLanguage){
                 i18n.changeLanguage(doc[0].data.prefLanguage.split('-')[0])
                 window.localStorage.setItem("lang",doc[0].data.prefLanguage.split('-')[0])
                }
                 else if(window.localStorage.getItem("lang")){
                 i18n.changeLanguage(window.localStorage.getItem("lang"))
                }
              } else {
                if(window.localStorage.getItem("lang")){
                  i18n.changeLanguage(window.localStorage.getItem("lang"))
                 }
              }

              if (doc[1].data) {
                dispatch({
                  type: "ADD_ENROLL",
                  payload: { url: user.photoURL, ...doc[1].data },
                });
              }
              if (doc[0].data && doc[0].data.role === "PATIENT" && doc[0].data.diagnosis) {
                dispatch({
                  type: "ADD_DIAGNOSIS",
                  payload: doc[0].data.diagnosis,
                });
              }
              if (doc[0].data?.relatedPersons && doc[0].data.relatedPersons.length > 0) {
                getCareGiverPatientList(doc[0].data.id).then((data) => {
                  let diagnosis;
                  let behaviors = []
                  if (data.data && data.data.length > 0) {
                    data.data.forEach((dig) => {
                      if (dig.diagnosis?.status) {
                        diagnosis = dig.diagnosis
                      }
                      if (dig.behaviors && dig.behaviors.length > 0) {
                        if(behaviors.length> 0) {
                        behaviors =behaviors.concat(dig.behaviors)
                        }else {
                        behaviors = dig.behaviors
                        }
                      }
                    })
                    dispatch({
                      type: "ADD_PATIENT_INFO",
                      payload: data.data,
                    });
                  }
                  if (diagnosis) {
                    dispatch({
                      type: "ADD_DIAGNOSIS",
                      payload: diagnosis,
                    });
                  }
                  if (behaviors.length > 0) {
                    dispatch({
                      type: "ADD_BEHAVIOUR",
                      payload: behaviors,
                    });
                  }
                  setShowScreen(true);
                })
              } else {
                setShowScreen(true);
              }


            });
        } else {
          setShowScreen(true);
        }
      });
      if (props.firebase.messaging)
        props.firebase.messaging.onMessage((payload) => {
          const notification =  payload.data
          if (notification) {
            const notificationHandler = new Notification(notification.title, {
              body: notification.body,
              icon: notification.icon,
              image: notification.image
            });
            notificationHandler.addEventListener('click', function () {
              window.open(notification['click_action'], '_blank');
            });
          }
        });
    } else {
      if(window.localStorage.getItem("lang")){
        i18n.changeLanguage(window.localStorage.getItem("lang"))
      }
      setShowScreen(true);
    }
    return () => {
      try {
        reset();
        reset1();
        dfTimeOut();
        unsubscribe();
      } catch {
         //window.location.href = "/login"
      }
    };
  }, []);

  const getUserData = (email) => {
    return axios.get("/fhir/user?email=" + email, {
      headers: { uid: localStorage.getItem("uid") },
    });
  };

  const askForPermissionToReceiveNotifications = async (res) => {
    let payload;
    setShowLoading(true);
    if (res === 'YES') {
      if (Notification.permission === 'denied') {
        dispatch({
          type: "ADD_ERROR",
          payload: {
            open: true,
            type: "warning",
            message: "Please enable notifications setting on your device",
          },
        });
        setShowLoading(false);
        return
      }
      try {
        const messaging = props.firebase.messaging;
        if (messaging) {
          messaging.requestPermission();
          const token = await messaging.getToken();
          window.localStorage.token = token
          subscribeTokenToTopic(token)
          payload = { pushNotification: true, deviceId: token , topic:  state.enrollment.organization? notifications[state.enrollment.organization]: 'allDevices'};
          setShowNotification(false)
          setShowLoading(false);
          dispatch({
            type: "ADD_ERROR",
            payload: {
              open: true,
              type: "success",
              message: t("Subscribed to push notification"),
            },
          });
        } else {
          payload = { pushNotification: false, deviceId: 'Not Supported' };
          window.localStorage.token = 'Not Supported'
          setShowNotification(false)
          setShowLoading(false);
          dispatch({
            type: "ADD_ERROR",
            payload: {
              open: true,
              type: "warning",
              message: t("Current browser doesn't support push notification. Try Chrome"),
            },
          });
        }
      } catch (error) {
        window.localStorage.token = error
        payload = { pushNotification: false, notificationError: error.message };
        setShowNotification(false)
        setShowLoading(false);
        console.error(error);
      }
    } else {
      setShowNotification(false)
      setShowLoading(false);
      window.localStorage.token = 'No'
      payload = { pushNotification: false };
    }
    if (payload)
      props.firebase
        .insertUserNotification(state.fhirStore.email, 'notification', payload)
        .then((d) => {
        })
        .catch((error) => {
          dispatch({
            type: "ADD_ERROR",
            payload: {
              open: true,
              type: "error",
              message: "Something went wrong",
            },
          });
        });
  }

  const DFtimeoutHandler = () => {
    states.useContext = true;
    states.sessionId = Util.getInstance().getRandomSessionId();
  }

  const getEnrollment = (email) => {
    return axios.get(`/fhir/enrollment?email=${email}`);
  };

  function subscribeTokenToTopic(token) {
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + 'allDevices', {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key=' + process.env.REACT_APP_CLOUD_MESSAGE
      })
    }).then(response => {
      console.log('Subscribed');
    }).catch(error => {
      console.error(error);
    })
    if(state.enrollment.organization)
    {
      fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + notifications[state.enrollment.organization], {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'key=' + process.env.REACT_APP_CLOUD_MESSAGE
        })
      }).then(response => {
        console.log('Subscribed to org');
      }).catch(error => {
        console.error(error);
      })
    }
  }


  const getCommunityUser = (email) => {
    return axios.get(`/fhir/community/user?email=${email}`)
  }
  const handleOnIdle = (event) => {
    props.firebase.logout("/login");
  };

  const handleOnIdleClinician = (event) => {
    if (
      window.location.pathname === "/patient-view" ||
      window.location.pathname === "/patient-info" ||
      window.location.pathname === "/patient-test" || window.location.pathname === "/patient-profile"
      || window.location.pathname === "/caregiver-profile" || window.location.pathname === "/clinician-profile" 
    ) {
      props.firebase.logout("/login");
    }
  };

  const { reset } = useIdleTimer({
    timeout: appplicationTimeOut,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const { reset1 } = useIdleTimer({
    timeout: appplicationTimeOutClincian,
    onIdle: handleOnIdleClinician,
    debounce: 500,
  });

  const { dfTimeOut } = useIdleTimer({
    timeout: dfTimeout,
    onIdle: DFtimeoutHandler,
    debounce: 500,
  });

  return (
    <MuiThemeProvider theme={THEME}>
      <React.Fragment>
        {showLoading && <Spinner></Spinner>}
        {!showScreen && <Spinner></Spinner>}
        {showScreen && (
          <>
            {showNotification &&<div className="push-notif fadeInTop"><NotificationsActiveIcon></NotificationsActiveIcon><span style={{ margin: '0px 5px' }}>{t("Would you like to enable push notifications?")}</span><Button variant="outlined" style={{ margin: '10px' }} onClick={() => askForPermissionToReceiveNotifications('YES')}
              color="primary">{t("Yes")}</Button><Button variant="outlined"
                color="primary" onClick={() => askForPermissionToReceiveNotifications('NO')}>{t("No")}</Button></div>}
            <Router>
              <Suspense fallback={<Spinner></Spinner>}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={() => <LandingPage></LandingPage>}
                  />
                  <Route path={RouteConstant.loginPath}>
                    <Login></Login>
                  </Route>
                  <Route path={RouteConstant.signupPath}>
                    <Signup></Signup>
                  </Route>
                  <Route path={RouteConstant.termsPath}>
                    <Terms></Terms>
                  </Route>
                  <Route path={RouteConstant.rolePath}>
                    <Role></Role>
                  </Route>
                  <Route path={RouteConstant.mainPath}>
                    <Main></Main>
                  </Route>
                  <Route path={RouteConstant.answerPath}>
                    <Answer></Answer>
                  </Route>
                  <Route path={RouteConstant.resetPath}>
                    <Reset></Reset>
                  </Route>
                  <Route path={RouteConstant.advisorResPath}>
                    <Chat></Chat>
                  </Route>
                  <Route path={RouteConstant.advisorMMPath}>
                    <Chat></Chat>
                  </Route>
                  <Route path={RouteConstant.elderCarePath}>
                    <ElderCare></ElderCare>
                  </Route>
                  <Route path={RouteConstant.summaryPath}>
                    <Summary></Summary>
                  </Route>
                  <Route path={RouteConstant.patientInfo}>
                    <PatientInfo></PatientInfo>
                  </Route>
                  <Route path={RouteConstant.patientTest}>
                    <PatientTest></PatientTest>
                  </Route>
                  <Route path={RouteConstant.communitySignUp}>
                    <CommunitySignUp></CommunitySignUp>
                  </Route>
                  <Route path={RouteConstant.patientSuccess}>
                    <PatientTestSuccess></PatientTestSuccess>
                  </Route>
                  <Route path={RouteConstant.patientView}>
                    <PatientView></PatientView>
                  </Route>
                  <Route path={RouteConstant.systemError}>
                    <Wrong></Wrong>
                  </Route>
                  <Route path={RouteConstant.clinicianProfile}>
                    <Cprofile></Cprofile>
                  </Route>
                  <Route path={RouteConstant.careGiverProfile}>
                    <CareProfile></CareProfile>
                  </Route>
                  <Route path={RouteConstant.patientProfile}>
                    <PatProfile></PatProfile>
                  </Route>
                  <Route path={RouteConstant.contactus}>
                    <Contact></Contact>
                  </Route>
                  <Route path={RouteConstant.stressScore}>
                    <StressScore></StressScore>
                  </Route>
                  <Route path={RouteConstant.stressWelcome}>
                    <StressWelcome></StressWelcome>
                  </Route>
                  <Route path={RouteConstant.selection}>
                    <Selection></Selection>
                  </Route>
                  <Route path={RouteConstant.burnOut}>
                    <StressScore></StressScore>
                  </Route>
                  <Route path={RouteConstant.dementiaScreening}>
                    <StressScore></StressScore>
                  </Route>
                  <Route path={RouteConstant.phoneAuth}>
                    <PhoneAuth></PhoneAuth>
                  </Route>
                  <Route path={RouteConstant.careCoach}>
                    <CareCoach></CareCoach>
                  </Route>
                  <Route path={RouteConstant.tracker}>
                    <ActivityTracker></ActivityTracker>
                  </Route>
                  <Route path={RouteConstant.trackerReport}>
                    <ActivityTrackerReport></ActivityTrackerReport>
                  </Route>
                  <Route path={'/Profile'}>
                   <Profile></Profile>
                  </Route>
                  <Route path={'/RA'}>
                    <RASms></RASms>
                  </Route>     
                  <Route path={'/facebook'}>
                    <FBlogin></FBlogin>
                  </Route>   
                  <Route path={'/FBresponse'}>
                    <FBresponse></FBresponse>
                  </Route>          
                  <Route path={'/batch'}>
                    <Batch></Batch>
                  </Route>
                  <Route path={'/render'}>
                    <Render></Render>
                  </Route>
                  <Route path={'/help'}>
                    <Help></Help>
                  </Route>
                  <Route path='/resource-planner/test-info/:test?'>
                    <TInfo></TInfo>
                  </Route>
                  <Route path={'/admin'}>
                    <Admin></Admin>
                  </Route>
                  <Route path={'/Jobs'}>
                    <Jobs></Jobs>
                  </Route>
                  <Route path="*" component={Error} />
                </Switch>
              </Suspense>
            </Router>
          </>
        )}
        <Snackbar
          open={state.error.open}
          type={state.error.type}
          message={state.error.message}
          handleClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            dispatch({
              type: "ADD_ERROR",
              payload: {
                open: false
              }
            });
          }}
        ></Snackbar>
      </React.Fragment>
    </MuiThemeProvider>
  );
}

export default withFireBase(App);
