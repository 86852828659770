import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./English-en-translated.json";
import hi from "./Hindi-hi-translated.json";
import es from "./Spanish-es-translated.json";
import zh from "./Chinese-zh-translated.json";
import ko from "./Korean-ko-translated.json";
import fr from "./French-fr-translated.json";
import tl from "./Filipino-tl-translated.json";
import hr from "./Croatian-hr-translated.json";
import da from "./Danish-da-translated.json";
import nl from "./Dutch-nl-translated.json";
import fi from "./Finnish-fi-translated.json";
import fy from "./Frysian-fy-translated.json";
import de from "./German-de-translated.json";
import el from "./Greek-el-translated.json";
import it from "./Italian-it-translated.json";
import ja from "./Japanese-ja-translated.json";
import no from "./Norwegian-no-translated.json";
import pl from "./Polish-pl-translated.json";
import pt from "./Portuguese-pt-translated.json";
import pa from "./Punjabi-pa-translated.json";
import ru from "./Russian-ru-translated.json";
import sr from "./Serbian-sr-translated.json";
import sv from "./Swedish-sv-translated.json";
import te from "./Telegu-te-translated.json";
import fa from "./Farsi-fa-translated.json";
import bn from "./Bengali-bn-translated.json";
import ar from "./Arabic-ar-translated.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: "en",
    fallbackLng: "en", 

    keySeparator: false,

    interpolation: {
      escapeValue: false, 
    },

    resources: {
      en: {
        translations: en,
      },
      ar: {
        translations: ar,
      },
      hi: {
        translations: hi,
      },
      es : {
        translations : es
      },
      zh : {
        translations : zh
      },
      ko: {
        translations: ko
      },
      fr: {
        translations: fr
      },
      hr: {
        translations: hr
      },
      da: {
        translations: da
      },
      nl: {
        translations: nl
      },
      tl: {
        translations: tl
      },
      fi: {
        translations: fi
      },
      fy: {
        translations: fy
      },
      de: {
        translations: de
      },
      el: {
        translations: el
      },
      it: {
        translations: it
      },
      ja: {
        translations: ja
      },
      no: {
        translations: no
      },
      pl: {
        translations: pl
      },
      pt: {
        translations: pt
      },
      pa: {
        translations: pa
      },
      ru: {
        translations: ru
      },
      sr: {
        translations: sr
      },
      sv: {
        translations: sv
      },
      te: {
        translations: te
      },
      fa: {
        translations: fa
      },
      bn: {
        translations: bn
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
