export const firebase =
  process.env.REACT_APP_STAGE === "production"
    ? {
      apiKey: process.env.REACT_APP_API_KEY_PROD,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN_PROD,
      databaseURL: process.env.REACT_APP_DATABASE_URL_PROD,
      projectId: process.env.REACT_APP_PROJECT_ID_PROD,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET_PROD,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_PROD,
      appId: process.env.REACT_APP_API_ID_PROD,
      cloudMessagekey: process.env.REACT_APP_CLOUD_MESSAGE_PROD,
    }
    : {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_API_ID,
      cloudMessagekey: process.env.REACT_APP_CLOUD_MESSAGE,
    };

export const oAuth =
  process.env.REACT_APP_STAGE === "production" ? {
    "clientId": process.env.REACT_APP_OAUTH_CLIENT_ID,
    "clientSecret": process.env.REACT_APP_OAUTH_CLIENT_SECRET_PROD,
  } : {
    "clientId": process.env.REACT_APP_OAUTH_CLIENT_ID,
    "clientSecret": process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  }


export const genAIRA =
  process.env.REACT_APP_STAGE === "production" ? {
    "clientId": process.env.REACT_APP_RA_GEN_AI_CLIENT_ID_DEV,
    "clientSecret": process.env.REACT_APP_RA_GEN_AI_CLIENT_SECRET_DEV,
  } : {
    "clientId": process.env.REACT_APP_RA_GEN_AI_CLIENT_ID_DEV,
    "clientSecret": process.env.REACT_APP_RA_GEN_AI_CLIENT_SECRET_DEV,
  }

export const mgmAUth = {
  "clientId": process.env.REACT_APP_MGM_CLIENT_ID,
  "clientSecret": process.env.REACT_APP_MGM_CLIENT_SECRET,
  "audience": process.env.REACT_APP_API_IDREACT_APP_MGM_AUD,
  "grant_type": "client_credentials"
}

export const authO =
  process.env.REACT_APP_STAGE === "production"
    ? {
      domain: process.env.REACT_APP_AUTHO_DOMAIN,
      clientID: process.env.REACT_APP_AUTHO_CLIENT_ID,
      responseType: 'token id_token',
      redirectUri: process.env.REACT_APP_AUTHO_REDIRECT_URL,
      db: process.env.REACT_APP_AUTHO_DB
    }
    : {
      domain: process.env.REACT_APP_AUTHO_DOMAIN,
      clientID: process.env.REACT_APP_AUTHO_CLIENT_ID,
      responseType: 'token id_token',
      redirectUri: process.env.REACT_APP_AUTHO_REDIRECT_URL_DEV,
      db: process.env.REACT_APP_AUTHO_DB
    };
export const appplicationTimeOut = 1000 * 60 * 60;
export const appplicationTimeOutClincian = 1000 * 60 * 5;
export const dfTimeout = 1000 * 60 * 4;

export const providerRedirect = "/resource-planner/patient-info";
export const patientProfile = "/resource-planner/patient-profile";
export const clinicianProfile = "/resource-planner/clinician-profile";
export const caregiverProfile = "/resource-planner/caregiver-profile";
export const patientView = "/resource-planner/patient-view";
export const patientProfileTab = "/resource-planner/patient/profile";

export const gMapKey = process.env.REACT_APP_MAP_KEY

