import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Firebase, { FirebaseContext } from "../src/context-store/";
import "../src/i18n/i18n";
import { ReduxContextProvider } from "./context-store/redux-context";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
TimeAgo.addDefaultLocale(en)

// const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// const script = document.createElement("script");
// script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}`;
// script.async = true;
// script.defer = true;
// document.head.appendChild(script);


let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
window.circleTab=[]
ReactDOM.render(
  <React.StrictMode>
    <ReduxContextProvider>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </ReduxContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
