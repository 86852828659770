import React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import CircularProgress from '@material-ui/core/CircularProgress';

function Spinner() {
  const override = {
    display: "block",
    position: "absolute",
    top: "0",
    bottom: "0px",
    left: "0",
    right: "0px",
    margin: "auto",
  };

  const bodyStyle = {
    height: "100vh",
    position: "fixed",
    width: "100%",
    top: "0px",
    bottom: 0,
    zIndex: 9,
    backgroundColor: "#ffffffab",
  };

  const imgDiv = {
    margin: "0 auto",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div style={bodyStyle}>
      <div style={imgDiv}>
        <img
          alt="ab-logo"
          style={{ width: "35px" }}
          src={require("../../src/assets/img/ab-icon.svg").default}
        ></img>
      </div>
      <CircularProgress style={override} thickness={1.5} size={80}/>
    </div>
  );
}

export default Spinner;
