import axios from "axios";

axios.interceptors.request.use((req) => {
   if (req.url.indexOf("api.zippopotam.us") > -1 || req.url.indexOf("www.zipcodeapi.com") > -1 ){
    return req;
   } else {
    req.headers.uid = localStorage.getItem("uid");
    req.headers.authorization = 'Bearer ' +sessionStorage.getItem("token");
    return req;
   }

});

export function linkPatientToClinician(payload, clinicianId) {
  return axios.put("/fhir/clinician/" + clinicianId + "/link/patient", payload);
}

export function getResource(id) {
  return axios.get("/resource-reminder-service/provider/" + id + "/resources");
}

export function getUser(id) {
  return axios.get(`/fhir/user?email=${id}`)
}

export function getCarePlans(patientID) {
  return axios.get("/fhir/careplan", { params: { patientId: patientID } });
}

export function postCarePlans(
  clinicianId,
  patientId,
  resouIds,
  testIds,
  nextAppointment,
  notes,
  resourceNotes,
  otherTest,
  edPayload
) {
  const tests = [];
  const resources = [];
  resouIds.forEach((element) => {
    resources.push({
      resourceId: element,
    });
  });
  testIds.forEach((element) => {
    tests.push({
      testId: element,
    });
  });
  if(otherTest['Blood Tests'] && otherTest['Blood Tests'].length > 0){
    otherTest['Blood Tests'].forEach((test)=> {
      const obj = {
        "test" : {
        "parent": 1001,
        "name": test
      }
    }
    tests.push(obj)
    })
  }
  if(otherTest['Imaging'] && otherTest['Imaging'].length > 0){
    otherTest['Imaging'].forEach((test)=> {
      const obj = {
        "test" : {
        "parent": 1002,
        "name": test
      }
    }
    tests.push(obj)
    })
  }
  if(otherTest['Lumbar Puncture'] && otherTest['Lumbar Puncture'].length > 0){
    otherTest['Lumbar Puncture'].forEach((test)=> {
      const obj = {
        "test" : {
        "parent": 1003,
        "name": test
      }
    }
    tests.push(obj)
    })
  }
  if(otherTest['Neuropsychology'] && otherTest['Neuropsychology'].length > 0){
    otherTest['Neuropsychology'].forEach((test)=> {
      const obj = {
        "test" : {
        "parent": 1004,
        "name": test
      }
    }
    tests.push(obj)
    })
  }
  let payload = {
    patientId,
    clinicianId,
    tests,
    resources,
    nextAppointment,
    notes,
    resourceNotes,
    scheduleRemindedTimes: 1,
  };
  if(edPayload){
    payload = {...payload, ...edPayload}
  }
   return axios.post("/fhir/careplan", payload);
}

export function getCareGiverPatientList(caregiverId) {
  return axios.get("/fhir/caregiver/" + caregiverId + "/patients");
}

export function isEnrolled(email) {
  return axios.get(`/fhir/enrollment?email=${email}`);
}

export function invitePatient(payload) {
  return axios.post("/fhir/email/patient", payload);
}

export function inviteUserCommunity(payload) {
  return axios.post("/fhir/community/invite", payload).then(() => {}, (err) => {console.log(err)});
}

export function renewalNotification(payload) {
  return axios.post("/fhir/email/renewal", payload).then(() => {}, (err) => {console.log(err)});
}

export function getGenderValue() {
  return axios.get("/fhir/genders")
}


export function getCounrtyValue() {
  return axios.get("/fhir/countries")
}

export function getPreferredLang() {
  return axios.get("/fhir/languages")
}

export function getRelationValue() {
  return axios.get("/fhir/relationshipTypes")
}

export function createProfile(url,payload) {
  return axios.post(url , payload);
}

export function getConditions() {
  return axios.get("fhir/patient/conditions")
}

export function putConditions(data){
  return axios.put("fhir/patient/conditions", data)
}

export function updateProfile(url,payload, id) {
  return axios.put(url + id,
payload
  );
}

export function createConsentForm(url,payload, id) {
  return axios.post(
    url + id + '/consent',
    payload
  );
}

export function linkCareGivertoPatient(careGiverId, payload) {
  return axios.put(
    '/fhir/caregiver/' + careGiverId + '/link/patients',
    payload
  );
}

export function  getRaceValue() {
  return axios.get('/fhir/relationshipTypes');
}


export function inviteUser(path,payload) {
  sendEmail(path,payload).then((d) => {});
}

export function sendEmail(url, payload) {
  return axios.post(url, payload);
}

export function  getMaritalValue() {
  return axios.get('/fhir/maritalStatuses');
}


export function createPatientDocument(payload) {
  return axios.post('/fhir/document', payload);
}

export function  getPatientLinkedCareGiver(patientId) {
  return axios.get(
    '/fhir/patient/' + patientId + '/caregivers'
  );
}

export function linkPatientToCaregiver(payload, patientId) {
  return axios.put(
   '/fhir/patient/' + patientId + '/link/caregivers',
    payload
  );
}

