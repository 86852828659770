import React, { useReducer, createContext } from "react";

export const ReduxContext = createContext();

const initialState = {
  fhirStore: {},
  enrollment: {},
  payload: {},
  diagnosis: {},
  hospitalData: {},
  resourceData: {},
  careplan: {},
  error:{},
  community:{},
  behaviors:[],
  zipCodeData: {},
  profile:{},
  patientInfo:[],
  selectedPatient:'',
  selectedPatientCarePlanner: '',
  careCoachReportData: [],
  trackerData:''
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ADD_FHIR":
      return { ...state, fhirStore: action.payload };
    case "ADD_ENROLL":
      return { ...state, enrollment: action.payload };
    case "ADD_PAYLOAD":
      return { ...state, payload: action.payload };
    case "ADD_HOSPITAL":
      return { ...state, hospitalData: action.payload };
    case "ADD_RESOURCE":
      return { ...state, resourceData: action.payload };
    case "ADD_CAREPLAN":
      return { ...state, careplan: action.payload };
    case "ADD_ERROR":
        return { ...state, error: action.payload };
    case "ADD_PROFILE" :    
        return { ...state, url: action.payload };
    case "ADD_ZIPCODE" :    
        return { ...state, zipCodeData: action.payload };
    case "ADD_DIAGNOSIS" :    
        return { ...state, diagnosis: action.payload };
    case "ADD_PATIENT_INFO" :    
        return { ...state, patientInfo: action.payload };
    case "ADD_REPORT_DATA" :    
        return { ...state, careCoachReportData: action.payload };
    case "ADD_SELECTED_PATIENT" :    
        return { ...state, selectedPatient: action.payload };
    case "ADD_SELECTED_PATIENT_CAREPLAN" :    
        return { ...state, selectedPatientCarePlanner: action.payload };
    case "ADD_BEHAVIOUR" :    
        return { ...state, behaviors: action.payload };
    case "ADD_PROFILE_PIC" :     
        return { ...state, url: action.payload };
    case "ADD_COMMUNITY_DATA" : 
    return { ...state, community: action.payload };
    case "ADD_SELECTED_USER_TRACKER" : 
    return { ...state, trackerData: action.payload };
    case "UPDATE_PAYLOAD":
      const payload = state.payload;
      const newPayload = { ...payload, [action.props]: action.value };
      return { ...state, payload: newPayload };
    default:
      throw new Error();
  }
};

export const ReduxContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ReduxContext.Provider value={[state, dispatch]}>
      {props.children}
    </ReduxContext.Provider>
  );
};
